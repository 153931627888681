import { Container } from '@mui/material';
import Page from '#/components/shared/ui/Page';
import ContactAuth from '#/components/pages/ContactUs/ContactAuth';

export default function ContactUs() {
  return (
    <Page title="Contact us">
      <Container component="main">
        <ContactAuth />
      </Container>
    </Page>
  );
}
