import { Box, Card, Typography, Divider, Stack, Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast';
import {
  StyledFormControlLabel,
  StyledRadioGroup,
  StyledRadio,
} from './styles';
import { FormProvider, RHFTextField } from '#/components/shared/hook-form';
import { useContactUs } from '#/api/userQueries';
import useAuth from '#/hooks/useAuth';
import { contactData } from './contactData';
import useLocales from '#/hooks/useLocales';

type FormValuesProps = {
  type: string;
  message: string;
};

export default function ContactAuth() {
  const { user } = useAuth();
  const { translate } = useLocales();

  const isPartner =
    user?.partner?.role === 'admin' ||
    user?.partner?.role === 'consultant' ||
    user?.partner?.role === 'owner';

  const { mutateAsync: contactUs } = useContactUs();

  const ValidationSchema = Yup.object().shape({
    type: Yup.string().required('A choice is required'),
    message: Yup.string().required('Please put a message'),
  });

  const defaultValues = {
    type: '',
    message: '',
  };

  const methods = useForm({
    resolver: yupResolver(ValidationSchema),
    defaultValues,
  });

  const { handleSubmit, control, formState, reset } = methods;
  const onSubmit = async (data: FormValuesProps) => {
    const requestData = {
      ...data,
      email: user?.email,
      first_name: user?.first_name,
      last_name: user?.last_name,
    };

    await contactUs(requestData)
      .then(() => {
        toast.success(
          String(translate('toast_notifications.success.message_test'))
        );
        reset();
      })
      .catch(() => {
        toast.error('Message not sent');
      });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Typography
          component="h1"
          variant="h2"
          gutterBottom
          textAlign="center"
          sx={{
            marginBottom: 10,
          }}
        >
          {String(translate('global.footer.contact'))}
        </Typography>

        <Card sx={{ p: 3 }}>
          <Stack sx={{ m: 1 }}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              {String(
                translate(
                  `global.contactUs.${
                    isPartner ? 'partnerTitle' : 'getLegalSupport'
                  }`
                )
              )}
            </Typography>
            <Divider />
          </Stack>
          {!isPartner && (
            <Typography sx={{ ml: 1 }}>
              {String(translate('global.contactUs.description'))}
            </Typography>
          )}
          {!isPartner && (
            <Controller
              name="type"
              control={control}
              render={({ field: { onChange, value } }) => (
                <StyledRadioGroup
                  name="service"
                  value={value}
                  onChange={onChange}
                >
                  <Stack
                    direction={{
                      xs: 'column',
                      md: 'row',
                    }}
                    spacing={2}
                    marginTop={2}
                    marginBottom={2}
                  >
                    {contactData.map((serviceOption) => (
                      <StyledFormControlLabel
                        key={`type-${serviceOption.label}`}
                        value={serviceOption.value}
                        control={<StyledRadio />}
                        label={String(
                          translate(`global.services.${serviceOption.label}`)
                        )}
                      />
                    ))}
                  </Stack>
                </StyledRadioGroup>
              )}
            />
          )}
          <RHFTextField
            name="message"
            multiline
            rows={5}
            placeholder={String(
              translate('global.contactUs.pleaseWriteMessage')
            )}
          />
          <Divider sx={{ mb: 3, mt: 3 }} />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button
              type="submit"
              disabled={!formState.isValid}
              variant="contained"
              color="primary"
              sx={{
                mr: 1,
              }}
            >
              {String(translate('global.formLabels.submit'))}
            </Button>
          </Box>
        </Card>
      </FormProvider>
    </Box>
  );
}
